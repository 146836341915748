import * as React from "react";
import { IDialogProps } from "../Interfaces/props";

function Contact({ job }: IDialogProps) {
  return (
    <div className="p-4 space-y-4">
      <div className="h-64 w-full mb-4">
        <div className="bg-gray-300 h-full w-full rounded-lg shadow-lg flex items-center justify-center">
          <p className="text-center text-2xl font-bold text-dunkelblau">Karte</p>
        </div>
      </div>
      <div className="bg-white shadow-lg rounded-lg p-4 space-y-2">
        <h2 className="text-2xl font-bold text-dunkelblau">Kontakt</h2>
        <div className="text-lg text-gray-800 space-y-2">
          <p>
            <strong>Name:</strong> {job.Employer.Name}
          </p>
          <p>
            <strong>Telefonnummer:</strong> +49 123 4567890
          </p>
          <p>
            <strong>Email:</strong> {"Email" /*job.Employer.Email*/}
          </p>
          <p>
            <strong>Webseite:</strong>{" "}
            <a href={job.Website.JobURL} className="text-blue-500 hover:underline">
              Link
            </a>
          </p>
          <p>
            <strong>Adresse:</strong>{" "}
            {
              "Straße 23, 12345 Stadt" /*job.Location.Street} {job.Location.Housenumber},
            {job.Location.PostalCode} {job.Location.City*/
            }
          </p>
        </div>
      </div>
    </div>
  );
}

export default Contact;
