import * as React from "react";
import { useState, useEffect } from "react";
import Frame from "./Dialog";
import { getJobTitles, getJobs, toggleSortOrder } from "../Clientlogic/Job";
import JobListItems from "./JobListItems";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import { IJob } from "../Interfaces/types";

function JobListFrame() {
  const [sortOrder, setSortOrder]: [string, React.Dispatch<React.SetStateAction<string>>] =
    useState("asc");
  const [jobs, setJobs]: [IJob[], React.Dispatch<React.SetStateAction<IJob[]>>] = useState<IJob[]>(
    [],
  );
  const [selectedJob, setSelectedJob]: [
    IJob | null,
    React.Dispatch<React.SetStateAction<IJob | null>>,
  ] = useState<IJob | null>(null);

  async function toggleOrder() {
    const { sortedJobs, newOrder } = await toggleSortOrder(sortOrder);
    setSortOrder(newOrder);
    setJobs(sortedJobs);
  }

  useEffect(function () {
    async function fetchData() {
      const JOBS: IJob[] = await getJobs();
      setJobs(JOBS);
    }

    fetchData();
  }, []);

  function openDialog(job: IJob) {
    setSelectedJob(job);
  }

  function closeDialog() {
    setSelectedJob(null);
  }

  return (
    <div className="max-w-screen-lg mx-auto px-4 sm:px-6 lg:px-8 py-6 font-sans">
      <h1 className="text-3xl font-bold text-dunkelblau mb-6">Job-Anzeigen</h1>
      <div className="overflow-auto max-h-screen">
        <button
          onClick={toggleOrder}
          className="px-4 py-2 bg-dunkelblau text-white rounded hover:bg-hellblau focus:outline-none flex items-center justify-center"
        >
          {sortOrder === "asc" ? (
            <ChevronUpIcon className="h-5 w-5" />
          ) : (
            <ChevronDownIcon className="h-5 w-5" />
          )}
        </button>
        <ul className="divide-y divide-gray-300 bg-white shadow rounded-lg">
          {jobs.map(function (job: IJob, index: number) {
            return (
              <JobListItems
                job={job}
                onClick={function () {
                  openDialog(job);
                }}
              />
            );
          })}
        </ul>
      </div>

      <Frame isOpen={!!selectedJob} job={selectedJob} onClose={closeDialog} />
    </div>
  );
}

export default JobListFrame;
