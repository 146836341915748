import * as React from "react";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { IJobListItemsProps } from "../Interfaces/props";

function JobListItems({ job, onClick }: IJobListItemsProps) {
  return (
    <li
      className="relative flex justify-between items-center py-5 px-4 hover:bg-hellgelb hover:bg-opacity-50 w-full cursor-pointer"
      onClick={onClick}
    >
      <div className="flex gap-x-4 pr-6 display:flex-none">
        <div className="min-w-0 flex-auto">
          <p className="text-lg font-semibold text-dunkelblau text-left">
            <button className="hover:text-orange text-left">
              <span className="absolute inset-x-0 -top-px bottom-0" />
              {job.Title}
            </button>
          </p>
          <p className="mt-1 text-sm text-hellblau text-left">{job.Employer.Name}</p>
        </div>
      </div>
      <ChevronRightIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
    </li>
  );
}

export default JobListItems;
