import * as React from "react";
import { Dialog, DialogPanel } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import Description from "./Description";
import Title from "./Title";
import Contact from "./Contact";
import { IFrameProps } from "../Interfaces/props";

function Frame({ isOpen, job, onClose }: IFrameProps) {
  return (
    <Dialog open={isOpen} onClose={onClose} className="fixed inset-0 z-50 overflow-y-auto">
      <div className="fixed inset-0 bg-schwarz bg-opacity-50"></div>
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <DialogPanel className="w-5/6 h-5/6 max-w-none flex flex-col bg-weiss p-6 shadow-2xl rounded-lg overflow-y-auto relative">
          <div className="sticky -top-6 bg-weiss pt-4  z-0">
            <button
              onClick={onClose}
              aria-label="Close Dialog"
              className="absolute top-4 right-4 focus:outline-none z-30"
            >
              <XMarkIcon className="w-8 h-8 text-dunkelblau hover:text-orange" />
            </button>
            {job && <Title job={job} />}
          </div>
          {job && (
            <div className="flex flex-col md:flex-row gap-4 mt-4">
              <div className="flex-1 md:flex-[3] bg-white shadow-lg rounded-lg p-4">
                <Description job={job} />
              </div>
              <div className="flex-1 md:flex-[1] bg-white shadow-lg rounded-lg p-4">
                <Contact job={job} />
              </div>
            </div>
          )}
        </DialogPanel>
      </div>
    </Dialog>
  );
}

export default Frame;
