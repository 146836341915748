import * as React from "react";
import { MapPinIcon, BriefcaseIcon, ClockIcon, GlobeAltIcon } from "@heroicons/react/20/solid";
import { IDialogProps } from "../Interfaces/props";

function Title({ job }: IDialogProps) {
  return (
    <div className="text-center">
      <p className="text-3xl font-bold text-dunkelblau mb-4 w-11/12">
        <a
          href={job.Website.JobURL}
          className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
        >
          {job.Title}
        </a>
      </p>
      <div className="flex flex-wrap justify-center gap-8 mb-4">
        <div className="flex items-center gap-2">
          <MapPinIcon className="h-5 w-5 text-dunkelblau" />
          <p className="text-dunkelblau">{"Location" /*job.Location.City*/}</p>
        </div>
        <div className="flex items-center gap-2">
          <BriefcaseIcon className="h-5 w-5 text-dunkelblau" />
          <p className="text-dunkelblau">{"Company" /*job.Company*/}</p>
        </div>
        <div className="flex items-center gap-2">
          <ClockIcon className="h-5 w-5 text-dunkelblau" />
          <p className="text-dunkelblau">{"Duration" /*job.Duration*/}</p>
        </div>
        <div className="flex items-center gap-2">
          <GlobeAltIcon className="h-5 w-5 text-dunkelblau" />
          <p className="text-dunkelblau">{"Language" /*job.Language*/}</p>
        </div>
      </div>
      <hr className="border-t-2 border-dunkelblau " />
    </div>
  );
}

export default Title;
