import * as React from "react";
import { IDialogProps } from "../Interfaces/props";

function Description({ job }: IDialogProps) {
  const JOBURL: string = "Zur Original Website";

  const JOBDescription: string =
    "Hier kommen die weitere Jobbeschreibungen hin. Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum itaque perspiciatis at est, animi atque dolor odit eius sed quo id nemo iusto minima debitis eum voluptatem omnis odio esse?";

  const REQUIREMENTS: string[] = [
    "Kenntnisse in React und Node.js",
    "Erfahrung mit RESTful APIs",
    "Fähigkeit, gut im Team zu arbeiten",
    "Gute Kommunikationsfähigkeiten",
  ];

  const BENEFITS: string[] = [
    "Wettbewerbsfähiges Gehalt",
    "Flexible Arbeitszeiten",
    "Home-Office Möglichkeit",
    "Weiterbildungsmöglichkeiten",
  ];

  return (
    <div className="max-w-screen-xl mx-auto bg-white">
      <div className="p-4 md:p-8">
        <h2 className="text-xl font-bold text-dunkelblau mb-4">{job.Title}</h2>
        <p className="text-gray-700">
          <a
            href={job.Website.JobURL}
            className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
          >
            {JOBURL}
          </a>
        </p>
        <p className="text-gray-700">{job.Description}</p>
      </div>
      <div className="p-4 md:p-8 border-t border-gray-200">
        <h2 className="text-xl font-bold text-dunkelblau mb-4">Anforderungen</h2>
        <ul className="text-gray-700">
          {REQUIREMENTS.map(function (requirement: string, index: number) {
            return (
              <li key={index} className="mb-2">
                <span className="inline-block mr-2 rounded-full bg-hellblau text-weiss px-2 py-1 text-xs font-semibold">
                  {index + 1}
                </span>
                {requirement}
              </li>
            );
          })}
        </ul>
      </div>
      <div className="p-4 md:p-8 border-t border-gray-200">
        <h2 className="text-xl font-bold text-orange mb-4">Benefits</h2>
        <ul className="text-gray-700">
          {BENEFITS.map(function (benefit: string, index: number) {
            return (
              <li key={index} className="mb-2">
                <span className="inline-block mr-2 rounded-full bg-orange text-weiss px-2 py-1 text-xs font-semibold">
                  {index + 1}
                </span>
                {benefit}
              </li>
            );
          })}
        </ul>
      </div>
      <div className="p-4 md:p-8 border-t border-gray-200">
        <h2 className="text-xl font-bold text-dunkelblau mb-4">Weitere Beschreibungen</h2>
        <p className="text-gray-700">{JOBDescription}</p>
      </div>
    </div>
  );
}

export default Description;
